import Vue from "vue";
import Router from "vue-router";
import NProgress from "nprogress";
import $ from "jquery";

import SubRouter from "./views/SubRouter.vue";
import { AuthService } from "./services/auth";
import { encontraTelaComPermissao } from "./helpers/permissions";

Vue.use(Router);

const BASE_TITLE = "Controle de Contas";
const TITLE_SEPARATOR = " | ";
const authService = new AuthService();

const router = new Router({
	mode: "history",

	routes: [{
		path: "/",
		name: "home",
		meta: {
			title: "Dashboard",
			perms: ["DASHBOARD_CALENDARIO_V"]
		},
		component: () => import("./views/Dashboard.vue")
	}, {
		path: "/gerenciamento",
		meta: {
			title: "Gerenciamento"
		},
		component: SubRouter,
		children: [{
			path: "/gerenciamento/groupsUsers",
			name: "gruposUsuarios",
			meta: {
				title: "Gerenciamento de Grupos de Usuários",
				perms: ["GER_V_GRUPO_USUARIO"]
			},
			component: () => import("./views/GruposUsuarios/List.vue")
		}, {
			path: "/gerenciamento/users",
			name: "usuarios",
			meta: {
				title: "Gerenciamento de Usuários",
				perms: ["GER_V_USUARIOS"]
			},
			component: () => import("./views/Usuarios/List.vue")
		}, {
			path: "/gerenciamento/categoryExpenses",
			name: "categoryExpenses",
			meta: {
				title: "Gerenciamento de Categoria de Transações",
				perms: ["GER_V_CATEGORIAS"]
			},
			component: () => import("./views/CategoryExpenses/List.vue")
		}, {
			path: "/gerenciamento/accountClass",
			name: "accountClass",
			meta: {
				title: "Gerenciamento de Tipos de Contas",
				perms: ["GER_V_TIPOS"]
			},
			component: () => import("./views/AccountClass/List.vue")
		}, {
			path: "/gerenciamento/Account",
			name: "account",
			meta: {
				title: "Gerenciamento de Contas",
				perms: ["GER_V_CONTAS"]
			},
			component: () => import("./views/Account/List.vue")
		}, {
			path: "/gerenciamento/Customer",
			name: "customer",
			meta: {
				title: "Gerenciamento de Clientes",
				perms: ["GER_V_CUSTOMER"]
			},
			component: () => import("./views/Customer/List.vue")
		}, {
			path: "/gerenciamento/ProductClass",
			name: "productClass",
			meta: {
				title: "Gerenciamento de Categoria de Produtos",
				perms: ["GER_V_PRODUTOS"]
			},
			component: () => import("./views/ProductClass/List.vue")
		}, {
			path: "/gerenciamento/Product",
			name: "product",
			meta: {
				title: "Gerenciamento de Produtos",
				perms: ["GER_V_PRODUTOS"]
			},
			component: () => import("./views/Product/List.vue")
		}]
	}, {
		path: "/commercialManagement",
		meta: {
			title: "Gestão Comercial"
		},
		component: SubRouter,
		children: [{
			path: "/commercialManagement/budget",
			name: "budget",
			meta: {
				title: "Orçamentos",
				perms: ["GER_V_ORCAMENTO"]
			},
			component: () => import("./views/CommercialManagement/Budget.vue")
		}]
	}, {
		path: "/transaction",
		meta: {
			title: "Transações"
		},
		component: SubRouter,
		children: [{
			path: "/transaction/incomeRecord",
			name: "incomeRecord",
			meta: {
				title: "Transações",
				perms: ["GER_V_RENDAS"]
			},
			component: () => import("./views/Transaction/IncomeRecord.vue")
		}, {
			path: "/transaction/expenseRecord",
			name: "expenseRecord",
			meta: {
				title: "Agendamento",
				perms: ["GER_V_AGENDAMENTOS"]
			},
			component: () => import("./views/Transaction/ExpenseRecord.vue")
		}, {
			path: "/transaction/receiveRecord",
			name: "receiveRecord",
			meta: {
				title: "A Receber",
				perms: ["GER_V_RECEIVE"]
			},
			component: () => import("./views/Transaction/ReceiveRecord.vue")
		}]
	}, {
		path: "/subscription",
		meta: {
			title: "Assinaturas"
		},
		component: SubRouter,
		children: [{
			path: "/subscriptions",
			name: "subscription",
			meta: {
				title: "Assinaturas",
				perms: ["GER_V_ASSINATURAS"]
			},
			component: () => import("./views/Subscriptions/List.vue")
		}]
	}, {
		path: "/report",
		meta: {
			title: "Relatórios"
		},
		component: SubRouter,
		children: [{
			path: "/report/AccountBalance",
			name: "accountBalance",
			meta: {
				title: "Saldo de Contas",
				perms: ["REL_V_CONTAS"]
			},
			component: () => import("./views/Reports/AccountBalance.vue")
		}, {
			path: "/report/ChartCategory",
			name: "chartCategory",
			meta: {
				title: "Despesas por Categorias",
				perms: ["DASHBOARD_V_CHART_CATEGORY"]
			},
			component: () => import("./views/Reports/ChartCategory.vue")
		}, {
			path: "/report/ChartAnalysis",
			name: "chartAnalysis",
			meta: {
				title: "Análise Comparativa",
				perms: ["DASHBOARD_V_CHART_GERAL"]
			},
			component: () => import("./views/Reports/ChartAnalysis.vue")
		}, {
			path: "/report/ChartCategoryToReceive",
			name: "chartCategoryToReceive",
			meta: {
				title: "Receitas por Categorias",
				perms: ["DASHBOARD_V_CHART_CATEGORY_INCOME"]
			},
			component: () => import("./views/Reports/ChartCategoryToReceive.vue")
		}]
	}, {
		path: "/perfilUsuario",
		name: "perfilUsuario",
		meta: {
			title: "Perfil de Usuário",
			perms: [],
			requiredAuth: true
		},
		component: () => import("./views/Usuarios/Profile.vue")
	}, {
		name: "Login",
		path: "/login",
		meta: {
			title: "Login",
			perms: []
		},
		component: () => import("./views/Login.vue")
	},
	// ERROS
	{
		name: "403",
		path: "/forbidden",
		meta: {
			title: "403 - Permissão insuficiente",
			perms: [],
			noRedirect: true
		},
		component: () => import("./views/403.vue")
	},

	// 404 (precisa ser a última rota deste arquivo)
	{
		name: "404",
		path: "*",
		meta: {
			title: "404 - Página não encontrada",
			perms: [],
			noRedirect: true
		},
		component: () => import("./views/404.vue")
	}]
});

router.beforeEach(async (to, from, next) => {
	// Obtém os metadados da rota de destino
	const meta = to.meta || {};

	// Altera título da janela de acordo com a página acessada
	document.title = (to.meta.title ? to.meta.title + TITLE_SEPARATOR : "") + BASE_TITLE;

	// Traz as permissões inerentes ao usuário
	let userPermissions = [];

	if (localStorage.params)
		userPermissions = (JSON.parse(localStorage.params).grupoUsuario).split(",");

	// Controle de permissões de acesso
	const permissions = meta.perms || [];
	if (permissions.length == 0) {
		// Página pública
		next();
	} else if (!(await authService.isLoggedIn())) {
		// Não logado
		await authService.logout();
		await authService.requireLogin();
	} else {
		let autorizado = false;
		for (const perm of permissions) {
			if (userPermissions.indexOf(perm) !== -1) {
				// Acesso autorizado
				autorizado = true;
				break;
			}
		}

		if (autorizado)
			return next();

		// A ideia é procurar uma página que o usuário possua permissão de acesso.
		// Caso não possua nenhuma permissão, ele será redirecionado
		// para uma página padrão (403).
		const result = encontraTelaComPermissao(userPermissions, router.options.routes);
		router.push({ name: result }).catch(e => {
			if (e.type != 8) console.log(e);
		});
	}
});

// Tooltips
router.afterEach(() => {
	$(".tooltip[role=tooltip]").tooltip("hide");
	setTimeout(() => {
		const $tooltips = $('[data-toggle="tooltip"]');
		if (window.innerWidth <= 790)
			$tooltips.tooltip("disable");
		else
			$tooltips.tooltip("enable");
	});
});

// Loading
NProgress.configure({ trickleRate: 0.1, trickleSpeed: 800, showSpinner: false });

router.beforeEach((to, from, next) => {
	NProgress.start();
	next();
});

router.afterEach(() => {
	NProgress.done();
});

export default router;
