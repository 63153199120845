const dayjs = require("dayjs");

/**
 * Copia por referência os valores de um objeto para outro e retorna este outro
 * @param {any} source objeto de origem
 * @param {Array<String|Number>} params array de parâmetros a serem copiados. ex.: ['id', 'nome', 'idade', 0]
 */
export const copyProps = source => params => params.reduce((acc, key) => { acc[key] = source[key]; return acc; }, {});

/**
 * Copia por valor (JSON parte and stringify) um objeto
 * @param {any} o objeto de origem
 */
export const copy = o => JSON.parse(JSON.stringify(o));

/**
 * Copia por valor (JSON parse and stringify) os valores de um objeto para outro e retorna este outro
 * @param {any} s objeto de origem
 * @param {Array<String|Number>} p array de parâmetros a serem copiados. ex.: ['id', 'nome', 'idade', 0]
 */
export const hardCopyProps = source => params => params.reduce((acc, key) => { acc[key] = copy(source[key]); return acc; }, {});

/**
 * Acessa propriedade profundas dentro de um objeto com checagem de null
 * @param {any[]} p vetor de path. ex.: ['user', 0, 'comments']
 * @param {any} o object
 *
 * @type {function(any[]): function(any): any}
 */
export const deep = p => o => p.reduce((acc, idx) => (acc && !(acc[idx] == null)) ? acc[idx] : null, o);

/**
 * Acessa propriedade profundas dentro de um objeto com checagem de null, com _dot notation_
 * @param {String} p path separado por pontos . ex.: 'user.0.comments'
 */
export const ddeep = p => deep(p.split("."));

/**
 * Retorna uma promessa que resolve após `ms` milissegundos. Internamente, essa função usa `setTimeout`.
 * @param {Number} ms milissegundos
 */
export const wait = ms => new Promise(res => setTimeout(res, ms));

/**
 * Função de debounce. Para utilizar o this do Vue dentro da função 'debounced', você DEVE fazer um bind para this
 * da seguinte forma: `debounce(function(){ ... }).bind(this)` ou `debounce(() => { ... }).bind(this)`.
 */
export const debounce = require("tiny-debounce");

/**
 * Função utilizada para transformar hora em String para formato ISO
 */
export function handleTimeOnly (time) {
	if (typeof time === "string")
		return time;

	return dayjs(time.toISOString().replace("Z", "")).format("HH:mm:ss");
}

/**
 * Retorna tempo em segundos em formato HH:MM:ss.
 * @param {Number} timeSeconds segundos
 */
export function formatTime (timeSeconds) {
	timeSeconds = parseInt(timeSeconds, 10);
	const hours = String(Math.floor(timeSeconds / 3600)).padStart(2, "0");
	timeSeconds %= 3600;
	const minutes = String(Math.floor(timeSeconds / 60)).padStart(2, "0");
	const seconds = String(timeSeconds % 60).padStart(2, "0");

	return `${hours}:${minutes}:${seconds}`;
}

/**
 * Recebe um numero e retorna uma string com separador(es) de milhares adicionado(s).
 * @example 1000000 => "1.000.000"
 * @param {Number} value inteiro
 */
export function numberFormatted (value) {
	value = parseFloat(value).toFixed(2);
	value = value.toString().replace(".", ",");
	const pattern = /(-?\d+)(\d{3})/;
	while (pattern.test(value))
		value = value.replace(pattern, "$1.$2");

	return value;
}

export function stringToNumber (value) {
	value = value.replace(/\./g, "");
	value = value.replace(",", ".");
	value = parseFloat(value);
	return value;
}

export function getDateMonthEnd (date) {
	const fim = new Date(date);
	const month = fim.getMonth();
	const year = fim.getFullYear();
	const ultimoDiaMes = new Date(year, month + 1, 0).getDate();
	fim.setDate(ultimoDiaMes);
	return fim;
}

/**
* @param {string or date} date Deve ser um string representado uma data com formato YYYY-MM-DD
*/
export function stringToDateUTC (date) {
	const isDate = date instanceof Date;
	if (isDate) {
		date = dayjs(date).locale("pt-br").format("YYYY-MM-DD");
	} else {
		const isFullDate = Boolean(date.length > 10);
		if (isFullDate)
			date = date.substring(0, 10);
	}

	date = date.split("-");
	date[1] = date[1] - 1;
	return new Date(Date.UTC(date[0], date[1], date[2], 12, 0, 0));
}

export const convertToCsv = (data) => {
	const arrayOfObjs = data && data.length > 0 ? data : [{}];

	const keys = Object.keys(arrayOfObjs[0]);
	let str = `${String(keys)}\r\n`;
	arrayOfObjs.forEach((item) => {
		let line = "";
		keys.forEach((key) => {
			if (line !== "") line += ",";
			line += `"${item[key]}"`;
		});

	  str = `${str}${line}\n`;
	});

	return str;
};

export const exportCSVFile = (arrayOfObjs, fileTitle) => {
	const csv = convertToCsv(arrayOfObjs);

	const exportedFilename = fileTitle ? `${fileTitle}.csv` : "file.csv";

	const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
	if (navigator.msSaveBlob) { // IE 10+
	  navigator.msSaveBlob(blob, exportedFilename);
	} else {
	  const link = document.createElement("a");
		if (link.download !== undefined) { // feature detection
		// Browsers that support HTML5 download attribute
			const url = URL.createObjectURL(blob);
			link.setAttribute("href", url);
			link.setAttribute("download", exportedFilename);
			link.style.visibility = "hidden";
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	}
};

export function validateNumber (value) {
	let valueNumber = value.replace(/[^\d.,]/g, "");
	valueNumber = valueNumber.replace(".", ",");

	if (!valueNumber.length) {
		return "";
	}

	let haveDecimalsSep = false;
	let newString = "";

	for (let i = 0; i < valueNumber.length; i++) {
		let chartEval = valueNumber[i];

		if (haveDecimalsSep && chartEval == ",")
			chartEval = "";

		if (chartEval == ",")
			haveDecimalsSep = true;

		newString = newString.concat(chartEval);
	}

	if (newString.length == 1 && newString == ",")
		return "0,";

	let posSepDec = 0;
	for (let i = 0; i < valueNumber.length; i++) {
		const chartEval = valueNumber[i];
		if (chartEval === ",") {
			posSepDec = i;
			break;
		}

	}

	valueNumber = valueNumber.replace(",", ".");
	if (posSepDec !== 0 && valueNumber.length > posSepDec + 3) {
		valueNumber = parseFloat(valueNumber).toFixed(2);
	}

	valueNumber = valueNumber.replace(".", ",");
	return valueNumber;
}

export function toFixedWithoutRounding (num, decimals) {
	const factor = Math.pow(10, decimals);
	return (Math.floor(num * factor) / factor).toFixed(decimals);
}

export function formatterSimple (date, withHour) {
	if (withHour)
		return dayjs(date).locale("pt-br").format("DD-MM-YYYY HH:mm:ss");

	return dayjs(date).locale("pt-br").format("DD-MM-YYYY");
}