<template>
	<nav class="navbar fixed-top pl-0">
		<div class="d-flex justify-content-between align-items-center w-100">
			<!-- Parte esquerda e central -->
			<div class="navbar-left d-flex align-items-center h-100">
				<!-- Trigger menu lateral e nome do sistema -->
				<div
					class="user-select-none init pr-3 d-flex justify-content-center align-items-center"
					:class="showBackButton ? 'mr-0 ' : 'mr-3'"
					style="min-height: 64px"
					ref="navbar-header"
				>
					<transition name="zoom-x">
						<div
							v-if="usuarioLogado"
							id="container-menu"
							class="toggle-menu p-3 mr-2 h-100 d-flex justify-content-center align-items-center"
							data-toggle="tooltip"
							data-placement="bottom"
							title="Mostrar/ocultar barra lateral"
							@click="menuButtonClicked"
						>
							<MenuIcon color="white"/>
						</div>
					</transition>
					<div class="d-flex align-items-center brand">
						<!-- <h1 id="logo-cal" class="mb-0 logo">SG</h1> -->
						<div class="produto d-flex justify-content-center align-items-center">
							<h2
								id="sg-cal"
								class="mb-0"
								v-bind:class="!usuarioLogado ? 'ml-2' : ''"
							>SG | Contas</h2>
						</div>
					</div>
				</div>

				<!-- Botão de voltar -->
				<div
					class="user-select-none go-back px-2 d-flex justify-content-center align-items-center"
					data-cy="Voltar"
					@click="backButtonClicked"
					title="Voltar"
					data-toggle="tooltip"
					data-placement="bottom"
					:class="{ 'force-hidden': !showBackButton }"
				>
					<ArrowLeftIcon color="var(--cal-header-color)"/>
				</div>

				<!-- <h2 class="module mb-0 pl-1">Controle de Contas</h2> -->
				<!-- <h3 class="secao mb-0 px-3">|</h3> -->

				<!-- Título da página -->
				<h2 class="mb-0 secao" data-cy="Titulo-pagina">{{ titulo }}</h2>
			</div>

			<!-- Versão, apenas no login -->
			<div
				class="h-100 mr-2 px-2"
				v-if="!usuario && $route.name === 'login'"
			>
				<ul
					tabindex="1"
					class="btn btn-light px-2 versao-badge"
					@click="changelog = true"
					@keypress="changelog = true"
					title="Changelog"
					data-toggle="tooltip"
					data-placement="bottom"
				>
					<li>WEB: {{ versaoWeb }}</li>
					<li>API: {{ versaoApi }}</li>
				</ul>
			</div>

			<!-- Parte direita -->
			<div
				class="navbar-right d-flex align-items-center h-100 pr-2 py-1"
				v-if="usuario"
			>
				<div class="user p-1 d-flex" @click="perfilUsuario()">
					<div class="avatar mr-2 text-white">
						<UserIcon name="user" label="Ícone de Usuário"/>
					</div>

					<div class="d-flex align-items-center">
						<div class="wrapper">
							<p class="name mb-0 cut-text d-block text-truncate" style="max-width: 122px;" :title="nomeUsuarioCompleto">
								<b>{{ usuario || "Desconectado" }}</b>
							</p>
						</div>
					</div>
				</div>

				<div
					class="p-3 d-flex"
					@click="logout()"
				>
					<LogOutIcon/>
				</div>
			</div>
		</div>

		<!-- Modal de changelog -->
		<changelog v-if="changelog" @close="changelog = false"/>
	</nav>
</template>

<script>
	const PLACEHOLDER_TITLE = "Controle";

	import { mapState } from "vuex";

	import { debounce } from "@/helpers/common";
	import Changelog from "./NavBar/Changelog.vue";

	import { AuthService } from "../services/auth";

	export default {
		components: { Changelog },

		props: {
			showBackButton: {
				type: Boolean,
				default: false
			}
		},

		data () {
			return {
				titulo: PLACEHOLDER_TITLE,
				maxWidthIntervalUpdates: 0,
				resFn: null,
				collapsed: true,
				usuario: "",
				nomeUsuarioCompleto: "",
				notificationCount: 0,
				versaoWeb: process.env.VUE_APP_VERSION || "-",
				versaoApi: process.env.API_APP_VERSION || "-",
				changelog: false,

				authService: new AuthService()
			};
		},

		computed: mapState(["usuarioLogado"]),

		methods: {
			menuButtonClicked () {
				this.$emit("menu-button-click");
			},

			backButtonClicked () {
				this.$emit("back-button-click");
			},

			toggleCollapsed () {
				this.collapsed = !this.collapsed;
			},

			setUser () {
				const storage = this.authService.getModuleStorage();
				this.usuario = (storage && storage.nomeUsuario) || "";
				this.nomeUsuarioCompleto = (storage && storage.nomeUsuarioCompleto) || "";
			},

			logout () {
				this.authService.logout();
			},

			perfilUsuario () {
				this.$router.push({ path: "/perfilUsuario"});
				// window.location.href = `${window.location.origin}/perfilUsuario`;
			}
		},

		watch: {
			"$route.meta.title" (newVal) {
				this.titulo = newVal || PLACEHOLDER_TITLE;
			},
			"usuarioLogado" (newVal) {
				this.setUser();
			}
		},

		async mounted () {
			this.setUser();
			this.resFn = debounce(() => {
				const hWidth = getComputedStyle(
					this.$refs["navbar-header"],
					null
				).getPropertyValue("width");

				this.$emit(
					"change-max-width",
					parseFloat(hWidth.replace("px", ""))
				);
			}, 300).bind(this);

			// Atualiza maxWidth ao redimensionar a janela do navegador
			window.addEventListener("resize", this.resFn);

			// Atualiza maxWidth de tempos em tempo nos primeiros minutos por causa do carregamento de fontes
			const UPDATE_INTERVAL = 5000; // ms
			const MAX_INTERVAL_UPDATES = Math.ceil((1 * 60 * 1000) / UPDATE_INTERVAL); // 1 minuto

			(
				function updateMaxWidth () {
					this.resFn();
					if (++this.maxWidthIntervalUpdates < MAX_INTERVAL_UPDATES) {
						setTimeout(updateMaxWidth.bind(this), UPDATE_INTERVAL);
					}
				}
			).bind(this)();
		},

		beforeDestroy () {
			window.removeEventListener("resize", this.resFn);
		}
	};
</script>

<style scoped>
	.force-hidden {
		display: none !important;
	}

	.navbar .configs,
	.navbar .apps {
		fill: none;
	}

	.navbar .user .avatar {
		background-color: var(--cal-header-color);
		padding: 0 12px 0 12px;
		border-radius: 5px;
		width: 50px;
		height: 48px;
		line-height: 48px;
	}

	.navbar .user .avatar svg {
		width: 26px;
		height: 26px;
	}

	.cursor-pointer {
		cursor: pointer;
	}

	.versao-badge {
		margin: 0;
		font-family: monospace;
		padding: 0;
		display: inline-block;
	}

	.versao-badge {
		vertical-align: initial;
	}

	.collapsed-menu {
		display: none;
	}

	.collapsed-menu svg {
		color: dimgray;
	}

	.secao {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.navbar .navbar-left {
		position: relative;
		flex: 1;
		min-width: 0;
	}

	.navbar .produto h2 {
		font-weight: 600;
	}

	.navbar .secao {
		font-weight: 300;
	}

	.navbar .module {
		font-weight: 600;
		color: var(--cal-header-color);
	}

	.apps {
		width: 56px;
	}

	@media screen and (max-width: 790px) {
		.collapsed-menu {
			display: flex;
		}

		.versao-badge {
			font-size: 10px !important;
		}

		.navbar .secao {
			display: none;
		}
	}

	@media screen and (max-width: 620px) {
		.module, .user {
			display: none !important;
		}

		#logo-cal, #pipe-tcs {
			display: none;
		}

		#sg-cal {
			align-self: center;
			padding-right: 0px;
		}

		#container-menu {
			padding-right: 0px !important;
			padding-left: 0px !important;
		}
	}

	@media (max-width: 595px) {
		.infoTurno{
			display: none !important;
		}
	}

	.p-3 {
		border-radius: 5px;
		cursor: pointer;
		box-sizing: content-box;
		border: 1px solid rgba(0, 0, 0, 0);
		padding: 0.375rem 0.75rem;
	}

	.p-3:hover, .p-3:active {
		border: 1px solid #ddd;
	}
</style>
