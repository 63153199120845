import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import dataTables from "./modules/dataTables";

const store = new Vuex.Store({
	strict: process.env.NODE_ENV === "development",
	modules: { dataTables },
	state: {
		fontScale: +(localStorage.getItem("controle/fonte")) || 1,
		sideBarWidth: 0,
		usuarioLogado: null
	},
	mutations: {
		updateSideBarWidth (state, w) {
			state.sideBarWidth = w;
		},

		setFontScale (state, scale) {
			if (!+scale) return;
			if (scale > 3) scale = 3;
			if (scale < 1) scale = 1;
			localStorage.setItem("controle/fonte", scale);
			state.fontScale = scale;
		},

		initUsuarioLogado (state, value) {
			state.usuarioLogado = value || null;
		},

		setUsuarioLogado (state, value) {
			if (value) {
				const userData = {
					usuario: value.user,
					token: value.token,
					grupoUsuario: value.permissoes.toString(),
					nomeUsuario: value.nomeUsuario,
					nomeUsuarioCompleto: value.nomeUsuarioCompleto,
					idUsuario: value.idUser,
					email: value.email,
					config: value.config,
					subscriptionActive: value.subscriptionActive,
					enterprise: value.enterprise,
					identityEnterprise: value.identityEnterprise,
					addressEnterprise: value.addressEnterprise,
					telephoneEnterprise: value.telephoneEnterprise
				};
				localStorage.setItem("params", JSON.stringify(userData));
				state.usuarioLogado = userData;
			} else {
				localStorage.removeItem("params");
				state.usuarioLogado = null;
			}
		}
	}
});

export default store;
