<template>
	<router-link :to="to" v-slot="{ href, navigate, isActive, isExactActive }" @click.native="$emit('link')" v-if="to">
		<li class="nav-item" :class="{ 'active': exact ? isExactActive : isActive }">
			<a class="nav-link" :href="href" @click="navigate" data-toggle="tooltip" data-placement="right" data-boundary="window" :title="name" :data-cy="name">
				<component :is="`${icon || 'link2'}-icon`" style="fill: transparent"/>
				<p class="mb-0">{{name}}</p>
			</a>
		</li>
	</router-link>
	<li class="nav-item" :class="{ 'active': !open && selected, 'hover': open }" v-else-if="children">
		<sub-menu-item ref="sub-menu" :name="name" :icon="icon || 'link2'" @toggle="toggle">
			<NavLink class="children-item" v-for="link in children" :key="link.name" v-bind="link" @link="$emit('link')"/>
		</sub-menu-item>
	</li>
	<li v-else></li>
</template>

<script>
	import SubMenuItem from "@/components/NavBar/SubMenuItem";

	export default {
		name: "NavLink",
		components: {
			SubMenuItem
		},
		props: {
			name: String,
			to: Object,
			icon: String,
			exact: {
				type: Boolean,
				default: true
			},
			children: {
				type: Array,
				default: () => []
			},
			openOnlyOneSubMenu: {
				type: Boolean,
				default: false
			}
		},
		data () {
			return {
				open: false,
				selected: false
			};
		},
		methods: {
			toggle (status) {
				this.open = status === "open";
				if (this.openOnlyOneSubMenu && this.open) {
					const subMenuNameActive = this.$refs["sub-menu"].name;

					this.$parent.$children.forEach((sb) => {
						if (sb.$refs && sb.$refs["sub-menu"]) {
							const subMenuName = sb.$refs["sub-menu"].name;
							if (subMenuNameActive != subMenuName) sb.$refs["sub-menu"].retracted = true;
						}
					});
				}

				this.selected = Array.prototype.some.call(this.$refs["sub-menu"].$el.lastChild.firstChild.children, el => el.className.includes("active"));
			}
		}
	};
</script>

<style scoped>
	.feather-dashboard {
		fill: currentColor !important;
	}

	.nav-link {
		text-overflow: ellipsis;
	}

	.nav-item svg {
		color: white;
	}

	.nav-item.active svg,
	.nav-item.active >>> .retracted svg {
		color: var(--cal-header-color);
	}

	.nav-item.active p,
	.nav-item.active >>> .retracted p {
		color: var(--cal-header-color);
		font-weight: 900;
	}

	.active:hover, .active.hover {
		background-color: #fff;
	}

	.children-item {
		padding-left: 1rem;
	}
</style>
