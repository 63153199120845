import { toFixedWithoutRounding } from "./common";
/**
 * Retorna um Boolean depois de verificar o numero de identidade.
 * @param {Number} x cpf/cnpj apenas numeros
 */
export function validateCPFCNPJ (x) {
	x = x.replace(/[.,-/]/g, "");
	if (x.length === 11 && Number(x))
		return valCPF(x);
	else if (x.length === 14 && Number(x))
		return valCNPJ(x);

	return false;
}

function valCPF (x) {
	const inputCPF = x;
	let aux = 0;
	let x1;
	// inicio:
	while (aux != 2) {
		const mCompl = 0.04;
		let mFactor = 10;
		let mMultip = 0;

		for (let i = 0; i <= 8; i++) {
			const mNumCPF = parseInt(x[i]);
			mMultip = mMultip + (mFactor * mNumCPF);
			mFactor = mFactor - 1;
		}

		let mResid = mMultip / 11;
		let mResidAux = toFixedWithoutRounding(mResid, 2);
		let lastChar = parseInt(mResidAux.toString().substring(mResidAux.length - 1));

		if (lastChar < 5)
			mResid = mResid + mCompl;

		let mResidPartInt = parseInt(mResid);
		mResid = parseFloat((mResid - mResidPartInt), 2);
		if (mResid > 0.94) {
			mResid = 10;
		} else {
			mResid = parseFloat(mResid - parseInt(mResid)).toFixed(1);
			mResid = mResid.toString().substring(mResid.length - 1);
		}

		let mVal1 = 11 - parseInt(mResid);
		if (mVal1 > 9)
			mVal1 = 0;

		// SEGUNDO VALIDADOR
		mFactor = 11;
		mMultip = 0;
		x = `${x.substring(0, 9)}${mVal1.toString()}`;

		for (let i = 0; i <= 9; i++) {
			const mNumCPF = parseInt(x[i]);
			mMultip = mMultip + (mFactor * mNumCPF);
			mFactor = mFactor - 1;
		}

		mResid = mMultip / 11;
		mResidAux = toFixedWithoutRounding(mResid, 2);
		lastChar = parseInt(mResidAux.toString().substring(mResidAux.length - 1));

		if (lastChar < 5)
			mResid = mResid + mCompl;

		mResidPartInt = parseInt(mResid);
		mResid = parseFloat((mResid - mResidPartInt)).toFixed(2);
		if (mResid > 0.94) {
			mResid = 10;
		} else {
			mResid = parseFloat(mResid - parseInt(mResid)).toFixed(1);
			mResid = mResid.toString().substring(mResid.length - 1);
		}

		let mVal2 = 11 - parseInt(mResid);
		if (mVal2 > 9)
			mVal2 = 0;

		const mValidCPF = mVal1.toString().concat(mVal2.toString());
		if (aux == 1)
			x = x1;

		if (mValidCPF == inputCPF.substring(inputCPF.length - 2)) {
			aux = 2;
			return true;
		} else {
			if (aux == 1) {
				aux = 2;
				return false;
			}
			const xAux = x.substring(0, 8).concat(mValidCPF);
			x1 = x;
			x = xAux;
			aux = 1;
		}
	}
}

function valCNPJ (x) {
	const inputCNPJ = x;
	let aux = 0;
	let x1;
	// inicio:
	while (aux != 2) {
		console.log(x);
		const mCompl = 0.04;
		let mFactor = 5;
		let mMultip = 0;

		for (let i = 0; i <= 3; i++) {
			const mNumCPNJ = parseInt(x[i]);
			mMultip = mMultip + (mFactor * mNumCPNJ);
			mFactor = mFactor - 1;
		}

		mFactor = 9;
		for (let i = 0; i <= 7; i++) {
			const mNumCPNJ = parseInt(x[i + 4]);
			mMultip = mMultip + (mFactor * mNumCPNJ);
			mFactor = mFactor - 1;
		}

		let mResid = mMultip / 11;
		let mResidAux = toFixedWithoutRounding(mResid, 2);
		let lastChar = parseInt(mResidAux.toString().substring(mResidAux.length - 1));

		if (lastChar < 5)
			mResid = mResid + mCompl;

		let mResidPartInt = parseInt(mResid);
		mResid = parseFloat((mResid - mResidPartInt), 2);

		if (mResid > 0.94) {
			mResid = 10;
		} else {
			mResid = parseFloat(mResid - parseInt(mResid)).toFixed(1);
			mResid = mResid.toString().substring(mResid.length - 1);
		}

		let mVal1 = 11 - parseInt(mResid);
		if (mResid < 2)
			mVal1 = 0;

		// SEGUNDO VALIDADOR
		mFactor = 6;
		mMultip = 0;
		for (let i = 0; i <= 4; i++) {
			const mNumCPNJ = parseInt(x[i]);
			mMultip = mMultip + (mFactor * mNumCPNJ);
			mFactor = mFactor - 1;
		}

		mFactor = 9;
		for (let i = 0; i <= 7; i++) {
			const mNumCPNJ = parseInt(x[i + 5]);
			mMultip = mMultip + (mFactor * mNumCPNJ);
			mFactor = mFactor - 1;
		}

		mResid = mMultip / 11;
		mResidAux = toFixedWithoutRounding(mResid, 2);
		lastChar = parseInt(mResidAux.toString().substring(mResidAux.length - 1));

		if (lastChar < 5)
			mResid = mResid + mCompl;

		mResidPartInt = parseInt(mResid);
		mResid = parseFloat((mResid - mResidPartInt)).toFixed(2);

		if (mResid > 0.94) {
			mResid = 10;
		} else {
			mResid = parseFloat(mResid - parseInt(mResid)).toFixed(1);
			mResid = mResid.toString().substring(mResid.length - 1);
		}

		let mVal2 = 11 - parseInt(mResid);
		if (mVal2 > 9)
			mVal2 = 0;

		const mValidCNPJ = mVal1.toString().concat(mVal2.toString());
		if (aux == 1)
			x = x1;

		if (mValidCNPJ == inputCNPJ.substring(inputCNPJ.length - 2)) {
			aux = 2;
			return true;
		} else {
			if (aux == 1) {
				aux = 2;
				return false;
			}
			const xAux = x.substring(0, 12).concat(mValidCNPJ);
			x1 = x;
			x = xAux;
			aux = 1;
		}
	}
}

export function formatIdentityDocument (document) {
	if (!document)
		return "";

	const value = document.replace(/[.,-/]/g, "");
	switch (value.length) {
		case 11: {
			return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
		}
		case 14: {
			return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
		}
		default: {
			return document;
		}
	}
}